import HeaderContainer_DoubleBorderScreenComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/DoubleBorderScreen/DoubleBorderScreen.skin';
import HeaderContainer_DoubleBorderScreenController from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/HeaderContainer.controller';


const HeaderContainer_DoubleBorderScreen = {
  component: HeaderContainer_DoubleBorderScreenComponent,
  controller: HeaderContainer_DoubleBorderScreenController
};


export const components = {
  ['HeaderContainer_DoubleBorderScreen']: HeaderContainer_DoubleBorderScreen
};

